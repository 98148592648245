import { Link } from "gatsby";
import React from "react";
import styled from "styled-components";

import { Color } from "../../../styles/";
import logo from "../../../assets/logo/logo.svg";
import FacebookLogo from "./assets/facebook";
import InstagramLogo from "./assets/instagram";

const Container = styled.div`
  position: fixed;
  z-index: 2;
  top: 0;
  left: 0;
  display: flex;

  width: 100%;
  height: 64px;

  color: rgba(255, 255, 255, 0.9);

  @media only screen and (max-width: 960px) {
    height: 72px;
    flex-wrap: wrap;
  }
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: #191919;
  transition: opacity 0.5s ease-in-out;
  box-shadow: 0 2px 2px 2px rgba(0, 0, 0, 0.1);
`;

const HeaderLogo = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  transition: opacity 0.5s ease-in-out;
  opacity: 0;

  @media only screen and (max-width: 960px) {
    width: 100%;
    opacity: 1;
  }

  &.highlight {
    opacity: 1;
  }

  > img {
    display: block;
    height: 20px;
    margin-left: 24px;

    @media only screen and (max-width: 960px) {
      width: auto;
      margin: 8px auto 0;
    }
  }

  > h1 {
    margin-left: 12px;
    letter-spacing: 0.15rem;
    font-weight: 600;
    text-transform: uppercase;
  }
`;

const Navigation = styled.nav`
  position: relative;
  flex: 1 1 auto;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media only screen and (max-width: 960px) {
    justify-content: space-around;
  }

  > a {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    box-sizing: border-box;
    margin-right: 24px;
    padding: 12px 8px 8px;
    font-size: 0.85rem;
    font-weight: 300;
    letter-spacing: 0.1rem;
    color: rgba(255, 255, 255, 0.9);
    text-decoration: none;
    text-transform: uppercase;
    text-shadow: 0 0 4px rgba(0, 0, 0, 0.9);

    border-bottom: 2px solid transparent;

    &:hover {
      border-bottom: 2px solid rgba(255, 255, 255, 0.9);
    }
    &.active {
      border-bottom: 2px solid ${Color.ORANGE_PRIMARY};
      color: #fff;
    }

    @media only screen and (max-width: 960px) {
      margin-right: 0;
      font-size: 0.75rem;
    }
  }
`;

const Social = styled.div`
  position: relative;
  display: flex;
  align-items: center;

  @media only screen and (max-width: 960px) {
    display: none;
  }
  > a > svg {
    width: 24px;
    height: 24px;
    margin-right: 12px;
    opacity: 0.7;

    &:hover {
      opacity: 1;
    }
  }
`;

const currentDate = new Date();
export default class Header extends React.Component {
  setHighlight = () => {
    this.setState({ highlight: window.scrollY !== 0 });
  };

  constructor(props) {
    super(props);
    this.state = {
      highlight: typeof window !== `undefined` ? window.scrollY !== 0 : false,
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.setHighlight);
  }

  render() {
    return (
      <Container>
        <Background style={{ opacity: this.state.highlight ? 1 : 0 }} />
        <HeaderLogo className={this.state.highlight ? "highlight" : null}>
          <img src={logo} alt="Platoon DJ Logo" />
        </HeaderLogo>
        <Navigation>
          <Link to="/" activeClassName="active">
            Hem
          </Link>
          <Link to="/brollop/" activeClassName="active">
            Bröllop
          </Link>
          <Link to="/foretagsevent/" activeClassName="active">
            Företag
          </Link>
          <Link to="/privatfest/" activeClassName="active">
            Privat
          </Link>
          {currentDate.getMonth() >= 7 && currentDate.getMonth() <= 8 && (
            <Link to="/join/" activeClassName="active">
              Bli Medlem
            </Link>
          )}
        </Navigation>
        <Social>
          <a
            href="https://www.facebook.com/platoondjs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <FacebookLogo />
          </a>
          <a
            href="https://www.instagram.com/platoondjs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <InstagramLogo />
          </a>
        </Social>
      </Container>
    );
  }
}
