import React from "react";
import styled from "styled-components";
import PropTypes from "prop-types";
import { animateScroll } from "react-scroll";

import logo from "../../../assets/logo/logo.svg";
import { Color } from "../../../styles";
import FadeText from "./components/FadeText/FadeText";

// import FadeText from "./components/FadeText/FadeText";

const Container = styled.div`
  position: relative;
  width: 100%;
  overflow: hidden;
  margin-bottom: -24px;
`;

const VideoContainer = styled.div`
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  background-color: ${Color.BACKGROUND_DARK_PRIMARY};
`;

const Video = styled.video`
  @media (min-aspect-ration: 16/9) {
    width: 100%;
    height: auto;
  }
  @media (max-aspect-ration: 16/9) {
    width: aut;
    height: 100%;
  }
`;

const BannerContent = styled.div`
  position: absolute;
  z-index: 1;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  color: rgba(255, 255, 255, 0.9);
  text-shadow: 0 0 4px rgba(0, 0, 0, 0.9);

  > img {
    width: 200px;

    @media only screen and (max-width: 960px) {
      width: 160px;
    }
  }

  > h2 {
    font-size: 2.25rem;
    letter-spacing: 0.5rem;
    font-weight: 700;
    text-transform: uppercase;
    text-align: center;
    line-height: 140%;
    margin-top: 24px;
    margin-bottom: 6px;
    border-bottom: none;
    padding-bottom: 0;

    @media only screen and (max-width: 960px) {
      font-size: 2rem;
      letter-spacing: 0.4rem;
    }
  }

  > h3 {
    display: block;
    margin-left: auto;
    margin-right: auto;
    font-size: 0.875rem;
    font-weight: 300;
    letter-spacing: 0.2rem;
    text-align: center;
    line-height: 140%;
    text-transform: uppercase;
    border-bottom: none;
    @media only screen and (max-width: 960px) {
      font-size: 0.75rem;
      letter-spacing: 0.15rem;
      margin-left: 12px;
      margin-right: 12px;
    }
  }
`;

/* const ReadMoreLabel = styled.div`
  position: absolute;
  bottom: 72px;
  width: 100%;
  text-align: center;
  animation: MoveUpDownLabel 1s ease-in-out infinite;

  @keyframes MoveUpDownLabel {
    0%,
    100% {
      bottom: 72px;
    }
    50% {
      bottom: 76px;
    }
  }
`; */

const ReadMoreArrow = styled.div`
  position: absolute;
  z-index: 1;
  bottom: 24px;
  width: 100%;
  height: 54px;
  text-align: center;
  animation: MoveUpDown 1s ease-in-out infinite;

  &:hover {
    cursor: pointer;
  }
  > span {
    display: block;
    padding-bottom: 12px;
    user-select: none;
  }
  > svg {
    display: block;
    width: 24px;
    height: 12px;
    margin: 0 auto;
    stroke: rgba(255, 255, 255, 0.9);
    stroke-width: 1.2;
  }
  @keyframes MoveUpDown {
    0%,
    100% {
      bottom: 24px;
    }
    50% {
      bottom: 28px;
    }
  }
`;

export default class Banner extends React.Component {
  constructor(props) {
    super(props);
    this.state = { width: 0, height: 0 };
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener("resize", this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions);
  }

  render() {
    return (
      <Container
        style={{
          height: `calc(${this.props.fullHeight ? "100vh" : "50vh"} + 24px)`
        }}
      >
        <VideoContainer>
          <Video
            autoPlay
            muted
            loop
            style={{ minWidth: "100%", flex: "1 1 auto", minHeight: "100%" }}
          >
            <source src={this.props.videoUrl} type="video/mp4" />
          </Video>
        </VideoContainer>
        <BannerContent>
          <img src={logo} alt="Platoon logo" />
          <h2>Platoon DJs</h2>
          <h3>
            {this.props.tagline}{" "}
            {this.props.fadeTexts != null && this.props.fadeTexts.length > 0 ? (
              <FadeText texts={this.props.fadeTexts} />
            ) : null}
          </h3>
        </BannerContent>

        {this.props.fullHeight ? (
          <ReadMoreArrow
            key="readMoreArrow"
            onClick={() => {
              animateScroll.scrollTo(this.state.height - 24, { duration: 500 });
            }}
          >
            <span>Läs mer</span>
            <svg viewBox="0 0 24 12">
              <line x1="0" y1="0" x2="12" y2="12" />
              <line x1="24" y1="0" x2="12" y2="12" />
            </svg>
          </ReadMoreArrow>
        ) : null}
      </Container>
    );
  }
  updateWindowDimensions() {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  }
}

Banner.propTypes = {
  fullHeight: PropTypes.bool,
  videoUrl: PropTypes.string.isRequired,
  tagline: PropTypes.string.isRequired,
  fadeTexts: PropTypes.array
};
