import React from "react";
import styled from "styled-components";

import facebookLogo from "../../../assets/icons/facebook.svg";
import instagramLogo from "../../../assets/icons/instagram.svg";
import spotifyLogo from "../../../assets/icons/spotify.svg";
import mixcloudLogo from "../../../assets/icons/mixcloud.svg";
import soundcloudLogo from "../../../assets/icons/soundcloud.svg";

import { Link } from "gatsby";

const Footer = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 60px 0 24px;
  background-color: #191919;
  color: rgba(255, 255, 255, 0.7);
  font-size: 0.875rem;
`;

const Columns = styled.div`
  display: flex;
  flex-wrap: wrap-reverse;
  max-width: 800px;
  margin: 0 auto;

  @media only screen and (max-width: 960px) {
    width: auto;
    margin: 0 24px;
  }
`;

const Column = styled.div`
  flex: 1 1 33%;

  @media only screen and (max-width: 960px) {
    flex: 1 1 100%;
    margin-bottom: 12px;
  }

  > h5 {
    font-size: 1rem;
    color: rgba(255, 255, 255, 0.9);
    margin-bottom: 18px;
  }

  > ul {
    margin-bottom: 24px;
  }
  > ul > li {
    margin: 10px 0;
  }

  > ul > li > a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }
`;

const P = styled.p`
  font-size: 0.875rem;
  margin-bottom: 24px;

  > a {
    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;
  }
`;

const SocialList = styled.div`
  > a {
    display: flex;
    align-items: center;
    margin: 10px 0;

    color: rgba(255, 255, 255, 0.7);
    text-decoration: none;

    > img {
      width: 20px;
      margin-right: 12px;
    }
  }
`;

export default () => (
  <Footer>
    <Columns>
      <Column>
        <h5>Våra tjänster</h5>
        <ul>
          <li>
            <Link to="/brollop/">Bröllop</Link>
          </li>
          <li>
            <Link to="/foretagsevent/">Företagsevent</Link>
          </li>
          <li>
            <Link to="/privatfest/">Privatfest</Link>
          </li>
        </ul>
      </Column>
      <Column>
        <h5>Hitta oss på</h5>
        <SocialList>
          <a
            href="https://www.facebook.com/platoondjs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Platoon DJs Facebook" src={facebookLogo} />
            <span>Facebook</span>
          </a>
          <a
            href="https://www.instagram.com/platoondjs/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Platoon DJs Instagram" src={instagramLogo} />
            <span>Instagram</span>
          </a>
          <a
            href="https://open.spotify.com/user/platoondjs"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img alt="Platoon DJs Spotify" src={spotifyLogo} />
            <span>Spotify</span>
          </a>
          <a href="https://www.mixcloud.com/PlatoonDJs/">
            <img alt="Platoon DJs Mixcloud" src={mixcloudLogo} />
            <span>Mixcloud</span>
          </a>
          <a href="https://soundcloud.com/platoon-djs">
            <img alt="Platoon DJs Soundcloud" src={soundcloudLogo} />
            <span>Soundcloud</span>
          </a>
        </SocialList>
      </Column>
      <Column>
        <h5>Kontakt</h5>
        <P>
          Platoon DJs
          <br />
          Drottning Kristinas Väg 15-19
          <br />
          100 44 Stockholm
        </P>

        <P>
          +46 (0)79 - 075 08 80
          <br />
          <a href="mailto:bokning@platoon.se">bokning@platoon.se</a>
        </P>
      </Column>
    </Columns>
    <Columns>
      <Column>
        <P
          style={{
            textAlign: "center",
            marginTop: "48px",
            color: "rgba(255, 255, 255, 0.5)",
          }}
        >
          &copy;Platoon DJs {new Date().getFullYear()} | ORG: 802496-9357 | Vi
          innehar F-skattesedel
        </P>
      </Column>
    </Columns>
  </Footer>
);
