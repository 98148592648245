import React from "react";

export default () => (
  <svg x="0px" y="0px" width="512px" height="512px" viewBox="0 0 49.652 49.652">
    <g>
      <g>
        <path
          d="M24.826,0C11.137,0,0,11.137,0,24.826c0,13.688,11.137,24.826,24.826,24.826c13.688,0,24.826-11.138,24.826-24.826    C49.652,11.137,38.516,0,24.826,0z M31,25.7h-4.039c0,6.453,0,14.396,0,14.396h-5.985c0,0,0-7.866,0-14.396h-2.845v-5.088h2.845    v-3.291c0-2.357,1.12-6.04,6.04-6.04l4.435,0.017v4.939c0,0-2.695,0-3.219,0c-0.524,0-1.269,0.262-1.269,1.386v2.99h4.56L31,25.7z    "
          fill="rgba(255, 255, 255, 0.9)"
        />
      </g>
    </g>
  </svg>
);
