import React from "react";
import PropTypes from "prop-types";
import styled from "styled-components";

const Container = styled.div`
  position: relative;
  display: inline-block;
  vertical-align: top;
`;

const Text = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  transition: opacity 1s ease-in-out;
`;

export default class FadeText extends React.Component {
  constructor(props) {
    super(props);
    this.timer = null;
    this.state = {
      current: 0,
      timer: null
    };
  }

  componentDidMount() {
    this.timer = setInterval(this.fade, 3000);
    //this.setState({ timer: timer });
  }

  componentWillUnmount() {
    clearInterval(this.timer);
  }

  render() {
    return (
      <Container
        style={{
          width: this.props.texts.reduce(
            (prev, cur) =>
              this.getTextWidth(cur) > prev ? this.getTextWidth(cur) : prev,
            0
          )
        }}
      >
        {this.props.texts.map((text, i) => (
          <Text
            key={"fadeText" + i}
            style={{ opacity: this.state.current === i ? 1 : 0 }}
          >
            {text}
          </Text>
        ))}
      </Container>
    );
  }

  fade = () => {
    if (this.props.texts.length > 1) {
      this.setState({
        current:
          this.state.current !== this.props.texts.length - 1
            ? this.state.current + 1
            : 0
      });
    }
  };

  getTextWidth = text => {
    if (typeof document === `undefined`) {
      return 160;
    }
    let font = "bold 12pt arial";
    // re-use canvas object for better performance
    let canvas =
      this.getTextWidth.canvas ||
      (this.getTextWidth.canvas = document.createElement("canvas"));
    let context = canvas.getContext("2d");
    context.font = font;
    let metrics = context.measureText(text);
    return metrics.width;
  };
}

FadeText.propTypes = {
  texts: PropTypes.array.isRequired
};
